import '../styles/common.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { BgWrapper } from '../components/shared/components';
import Constants from '../constants/api';
import DownloadAppBanner from '../components/shared/download-app-banner';
import InfoIconPopup from '../components/shared/info-icon-popup/info-icon-popup';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../components/shared/section-titles';
import { TitleH1 } from './magazini';
import { graphql } from 'gatsby';
import meta from '../constants/meta-descriptions';
import styled from 'styled-components';

const initialState = {
	firstName: null,
	lastName: null,
	email: null,
	phone: null,
	description: null,
	reason: null
};

const appStoreLink = 'https://apps.apple.com/bg/app/netsurf-play/id1200797767';
const googlePlayLink =
	'https://play.google.com/store/apps/details?id=io.bits84.netsurfplay';
const INFO = [
	{
		title: 'Техническа поддръжка',
		tooltip: 'Денонощна помощ 24/7',
		phone: '0885 25 0000',
		option: 'Опция 1',
		email: 'support@net-surf.net'
	},
	{
		title: 'Търговски отдел',
		tooltip:
			'Понеделник - Петък: от 09:00 - 18:00 часа. Събота и Неделя: от 10:00 - 18:00 часа.',
		phone: '0885 25 0000',
		option: 'Опция 2',
		email: 'callcenter@net-surf.net'
	},
	{
		title: 'Заяви услуга',
		tooltip:
			'Понеделник - Петък: от 09:00 - 18:00 часа. Събота и Неделя: от 10:00 - 18:00 часа.',
		phone: '0882 911 611',
		option: undefined,
		email: 'sales@net-surf.net'
	}
];

const Row = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 5rem;
	gap: 60px;
	max-width: 900px;
	margin: auto;
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px 8px 16px 8px;
	border: 1px solid black;
	border-radius: 8px;
	align-items: center;
	height: 100%;
	justify-content: space-between;

	& > * {
		text-align: center;
	}

	h2 {
		margin-bottom: 0;
	}

	span {
		padding-top: 16px;
	}
`;

const isValidTelephone = (phone) => {
	const r = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
	return r.test(phone);
};

const isValidMail = (email) => {
	const r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return r.test(String(email).toLowerCase());
};

const areAllKeysNull = (obj) => {
	for (const key in obj) {
		if (obj[key] !== null) {
			return false; // If any key has a non-null value, return false
		}
	}
	return true; // If all keys have null values, return true
};

const areAllPropsTruthy = (obj) => {
	for (const key in obj) {
		if (!obj[key]) {
			return false;
		}
	}
	return true;
};

const validateField = (field, value) => {
	switch (field) {
		case 'firstName':
			return value === null ? true : value.length >= 1;

		case 'lastName':
			return value === null ? true : value.length >= 1;

		case 'phone':
			return value === null ? true : isValidTelephone(value);

		case 'email':
			return value === null ? true : isValidMail(value);

		case 'description':
			return value === null ? true : value.length >= 1;

		case 'reason':
			return value === null ? true : value.length >= 1;

		default:
			return true;
	}
};

const getSendToEmail = (reason) => {
	switch (reason) {
		case 'Технически':
			return 'support@net-surf.net';
		case 'Търговски':
			return 'sales@net-surf.net';
		case 'Финансови':
			return 'sales@net-surf.net';
		case 'Административни':
			return 'support@net-surf.net';

		default:
			return 'callcenter@netsurf.bg';
	}
};

export default ({ data }) => {
	const title = data.wordpressPage.acf.title;
	const [formState, setFormState] = useState(initialState);
	const [isAgreed, setIsAgreed] = useState(false);
	const [isGdprAccepted, setIsGdprAccepted] = useState(false);

	const updateState = useCallback(
		(event, field) => {
			event.preventDefault();
			event.persist();

			switch (field) {
				case 'firstName':
					setFormState((prevState) => ({
						...prevState,
						firstName: event?.target?.value
					}));
					break;
				case 'lastName':
					setFormState((prevState) => ({
						...prevState,
						lastName: event?.target?.value
					}));
					break;
				case 'phone':
					setFormState((prevState) => ({
						...prevState,
						phone: event?.target?.value
					}));
					break;
				case 'email':
					setFormState((prevState) => ({
						...prevState,
						email: event?.target?.value
					}));
					break;
				case 'reason':
					setFormState((prevState) => ({
						...prevState,
						reason: event?.target?.value
					}));
					break;

				case 'description':
					setFormState((prevState) => ({
						...prevState,
						description: event?.target?.value
					}));
					break;

				default:
					break;
			}
		},
		[formState, setFormState]
	);

	const onSubmit = useCallback(
		(e) => {
			e.preventDefault();

			const response = window?.grecaptcha?.getResponse();


			if (!isDisabled && response) {
				const subject = `${formState.reason} въпрос през сайта`;
				const reply_to = formState.email;
				const body =
					'<br>Име: ' +
					formState.firstName +
					'<br />Фамилия: ' +
					formState.lastName +
					'<br>Имейл: ' +
					formState.email +
					'<br>Телефон: ' +
					formState.phone +
					'<br>Описание: ' +
					formState.description;

				const emailObj = {
					send_to: getSendToEmail(formState.reason),
					subject: subject,
					body: body,
					reply_to: reply_to
				};

				const headers = new Headers();
				headers.append('Content-Type', 'application/json');

				const POSToptions = {
					method: 'POST',
					headers: headers,
					body: JSON.stringify(emailObj)
				};

				const request = new Request(Constants.MAIL_URL, POSToptions);

				fetch(request)
					.then(() => {
						setFormState(initialState);
						setIsAgreed(false);
						setIsGdprAccepted(false);
						toast.success('Формата беше изпратена успешно.', {
							position: toast.POSITION.BOTTOM_RIGHT, // Set position to bottom right
							autoClose: 3000, // Close after 5 seconds
							closeButton: true // Show close button
						});
					})
					.catch((e) => {
						toast.error('Възникна проблем, моля опитайте отново.', {
							position: toast.POSITION.BOTTOM_RIGHT,
							autoClose: 4000,
							closeButton: true
						});
					});
			}
		},
		[formState, isAgreed, isGdprAccepted]
	);

	const isDisabled = useMemo(() => {
		if (areAllKeysNull(formState)) {
			return true;
		}
		if (areAllPropsTruthy(formState) && isAgreed && isGdprAccepted) {
			return false;
		}
		return true;
	}, [formState, isAgreed, isGdprAccepted]);

	useEffect(() => {
		const renderRecaptcha = () => {
			if (window.grecaptcha) {
				window.grecaptcha.render('captcha');
			}
		};

		renderRecaptcha();
	}, []);

	return (
		<BgWrapper bg={data.bg.childImageSharp.fluid.src} position="top">
			<Layout>
				<SEO title={meta.faq.title} description={meta.faq.metaDescription} />
				<div style={{ paddingTop: '8.5rem' }}>
					<div style={{ maxWidth: 1280, margin: 'auto', padding: '32px 16px' }}>
						<SectionTitle>
							<TitleH1>{title}</TitleH1>
						</SectionTitle>

						<Row className="row d-flex justify-content-center my-5">
							{INFO.map((info) => (
								<div
									key={info.title}
									style={{
										display: 'flex',
										flex: 1,
										flexDirection: 'column'
									}}
								>
									<div
										className="my-1"
										style={{
											display: 'flex',
											gap: '10px',
											justifyContent: 'center'
										}}
									>
										<h3>{info.title}</h3>

										<InfoIconPopup
											popupText={info.tooltip}
											maxWidth="240px"
										/>
									</div>
									<Box>
										<div>
											<h2>{info.phone}</h2>
											<div>{info?.option}</div>
										</div>
										<span>{info.email}</span>
									</Box>
								</div>
							))}
						</Row>

						<SectionTitle>
							<TitleH1>Свържете се с нас</TitleH1>
						</SectionTitle>

						<form onSubmit={onSubmit}>
							<div className="form-row">
								<div className="form-group col-md-6">
									<label htmlFor="firstName">
										<span className="text-danger">*</span>Име:
									</label>
									<input
										type="text"
										className="form-control"
										id="firstName"
										placeholder="Въведете име"
										value={formState.firstName || ''}
										onFocus={(e) => (e.target.placeholder = '')}
										onBlur={(e) =>
											(e.target.placeholder = 'Въведете име')
										}
										onChange={(v) => updateState(v, 'firstName')}
									/>
									{!validateField('firstName', formState.firstName) && (
										<small className="text-danger">
											Минимум 2 символа
										</small>
									)}
								</div>
								<div className="form-group col-md-6">
									<label htmlFor="lastName">
										<span className="text-danger">*</span>Фамилия:
									</label>
									<input
										type="text"
										className="form-control"
										id="lastName"
										placeholder="Въведете име"
										value={formState.lastName || ''}
										onFocus={(e) => (e.target.placeholder = '')}
										onBlur={(e) =>
											(e.target.placeholder = 'Въведете фамилия')
										}
										onChange={(v) => updateState(v, 'lastName')}
									/>
									{!validateField('lastName', formState.lastName) && (
										<small className="text-danger">
											Минимум 2 символа
										</small>
									)}
								</div>

								<div className="form-group col-md-6">
									<label htmlFor="telephone">
										<span className="text-danger">*</span>Телефон:
									</label>
									<input
										type="text"
										className="form-control"
										id="telephone"
										placeholder="Въведете телефон"
										value={formState.phone || ''}
										onFocus={(e) => (e.target.placeholder = '')}
										onBlur={(e) =>
											(e.target.placeholder = 'Въведете телефон')
										}
										onChange={(v) => updateState(v, 'phone')}
									/>
									{!validateField('phone', formState.phone) && (
										<small className="text-danger">
											Невалиден телефон
										</small>
									)}
								</div>

								<div className="form-group col-md-6">
									<label htmlFor="email">
										<span className="text-danger">*</span>Имейл:
									</label>
									<input
										type="text"
										className="form-control"
										id="email"
										placeholder="Въведете имейл"
										value={formState.email || ''}
										onFocus={(e) => (e.target.placeholder = '')}
										onBlur={(e) =>
											(e.target.placeholder = 'Въведете имейл')
										}
										onChange={(v) => updateState(v, 'email')}
									/>
									{!validateField('email', formState.email) && (
										<small className="text-danger">
											Невалиден имейл
										</small>
									)}
								</div>
								<div className="w-100 d-flex justify-content-center">
									<div className="form-group col-md-6">
										<label htmlFor="reason">
											<span className="text-danger">*</span>Причина:
										</label>
										<select
											className="form-control"
											id="reason"
											value={formState.reason || ''}
											onChange={(v) => updateState(v, 'reason')}
										>
											<option value="">
												- Моля Изберете причина -
											</option>
											<option value="Технически">
												Технически въпроси
											</option>
											<option value="Търговски">
												Търговски въпроси
											</option>
											<option value="Финансови">
												Финансови въпроси
											</option>
											<option value="Административни">
												Административни въпроси
											</option>
										</select>
										{!validateField('reason', formState.reason) && (
											<small className="text-danger">
												Моля посочете причина
											</small>
										)}
									</div>
								</div>
								<div className="form-group col-md-12">
									<span className="text-danger">*</span>Описание:
									<textarea
										value={formState.description || ''}
										onChange={(v) => updateState(v, 'description')}
										className="form-control"
										id="description"
										rows="8"
										placeholder="Добавете описание (до 2000 символа)"
									/>
									{!validateField(
										'description',
										formState.description
									) && (
										<small className="text-danger">
											Минимум 5 символа
										</small>
									)}
								</div>
								<div
									className="form-group col-md-12"
									style={{ fontSize: '12px' }}
								>
									Всички полета, отбелязани със{' '}
									<span className="text-danger">*</span> са задължителни
								</div>

								<div className="form-group col-md-12">
									Нет-Сърф.Нет събира и обработва Вашите лични данни
									(Имена, електронен адрес и други данни, в зависимост
									от въведената информация),предоставени чрез тази
									форма, с цел извършване на администриране на вашето
									запитване.
								</div>
								<div
									className="form-group col-md-12 d-flex"
									style={{
										maxWidth: 800,
										margin: 'auto',
										gap: '10px'
									}}
								>
									<input
										type="checkbox"
										id="agreeCheckbox"
										checked={isAgreed}
										onChange={(e) => setIsAgreed(e.target.checked)}
									/>
									<label
										htmlFor="agreeCheckbox"
										style={{ marginBottom: 0 }}
									>
										Желая данните ми да бъдат обработвани за целите на
										изпратеното запитване
									</label>
								</div>
								<div
									className="form-group col-md-12 d-flex"
									style={{
										maxWidth: 800,
										margin: 'auto',
										gap: '10px'
									}}
								>
									<input
										type="checkbox"
										id="gdprCheckbox"
										checked={isGdprAccepted}
										onChange={(e) =>
											setIsGdprAccepted(e.target.checked)
										}
									/>
									<label
										htmlFor="gdprCheckbox"
										style={{ marginBottom: 0 }}
									>
										Съгласен съм да съберете личните ми данни по{' '}
										<a
											href="https://netsurf.bg/wp-content/uploads/2019/12/GDPR_02_12_2019.pdf"
											target="_blank"
											rel="noopener noreferrer"
										>
											GDPR
										</a>
										.
									</label>
								</div>
							</div>
							<div className="d-flex justify-content-center my-5">
								<div
									id="captcha"
									className="g-recaptcha"
									data-sitekey="6Lc1F84pAAAAAAXG5gUdzecdcm0iieKPeCvY_TQL"
								/>
							</div>
							<div className="w-100 text-center">
								<button
									type="submit"
									disabled={isDisabled}
									className="btn btn-primary green"
								>
									Изпрати
								</button>
							</div>
						</form>

						<div className="my-5 text-center">
							<SectionTitle
								undertext={'Открийте най-близкия до вас Netsurf магазин'}
							>
								<TitleH1>Нашите магазини в цяла България</TitleH1>
							</SectionTitle>

							<Link
								to={'/magazini/'}
								className="btn btn-primary green m-auto py-2"
							>
								Виж повече
							</Link>
						</div>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<div className="container d-flex justify-content-center align-items-center mb-5">
								<DownloadAppBanner
									className="text-center mt-5"
									googlePlayLink={googlePlayLink}
									appStoreLink={appStoreLink}
								/>
							</div>
						</ScrollAnimation>
					</div>
				</div>
				<ToastContainer />
			</Layout>
		</BgWrapper>
	);
};

export const query = graphql`
	query contact {
		wordpressPage(slug: { eq: "contact" }) {
			acf {
				title
			}
		}
		bg: file(relativePath: { eq: "netsurfplay/bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;

